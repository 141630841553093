.expanded-table {
  width: 100%;
  .expanded-table-name-cell {
    display: flex;
    .expanded-table-remove-button {
      padding: 4px;
    }
    .expanded-table-edit-button {
      background: #fff;
      font-size: 14px;
      text-transform: none;
    }
  }
}
