.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contains {
  position: relative;
  max-width: 640px;
  width: 100%;
  background-color: #fff;
  &:focus {
    outline: none;
  }
}
.content {
  padding: 25px;
}