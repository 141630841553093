.root {
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: 350px;
  overflow: auto;
}

.listSection {
  background-color: #fff;
  width: 100%;
}

.ul {
  background-color: inherit;
  padding: 0;
}

.listSubheader {
  width: 100%;
}
