.root {
    margin: 0;
    padding: 2px;
    &.MuiDialog-paperWidthSm {
      max-Width: 645px;
    }
  }

.form {
  width: 600px;
}

.closeButton {
    position: absolute;
    right: 1px;
    top: 1px;
    color: rgb(128, 128, 128);
  }

.buttonContainer {
    display: flex;
    justify-Content: space-between;
    margin: 0 -5px;
    & button {
      margin: 0 5px;
    }
  }

.catalogSpec {
    & MuiPaper-root {
      max-height: 350px;
      height: 350px;
      &::-webkit-scrollbar {
        width: 8px !important;
      }
      &::-webkit-scrollbar-track {
        background: '#f1f1f1',
      }
      &::-webkit-scrollbar-thumb {
        background: '#888',
      }
      &::-webkit-scrollbar-thumb:hover {
        background: '#555',
      }
    }
  }

.list {
    display: flex;
    flex-direction: column;
  }

  .err {
    color:rgb(228, 37, 37)
  }

  .label {
    color: rgb(27, 43, 75);
    line-height: 17px;
    font-Family: 'Proxima Nova Light',
  }

  .listEditor {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
