@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';

@import url(
  http://fonts.googleapis.com/css?family=Roboto:400,
  100,
  100italic,
  300,
  300ita‌​lic,
  400italic,
  500,
  500italic,
  700,
  700italic,
  900italic,
  900
);
html,
body,
html * {
  font-family: 'Roboto', sans-serif;
}

@font-face {
  font-family: 'Proxima Nova Thin';
  src: url('./assets/fonts/ProximaNovaT-Thin.eot');
  src: url('./assets/fonts/ProximaNovaT-Thin.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/ProximaNovaT-Thin.woff') format('woff'),
    url('./assets/fonts/ProximaNovaT-Thin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Thin Italic';
  src: url('./assets/fonts/ProximaNova-ThinIt.eot');
  src: url('./assets/fonts/ProximaNova-ThinIt.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/ProximaNova-ThinIt.woff') format('woff'),
    url('./assets/fonts/ProximaNova-ThinIt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Light';
  src: url('./assets/fonts/ProximaNova-Light.eot');
  src: url('./assets/fonts/ProximaNova-Light.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/ProximaNova-Light.woff') format('woff'),
    url('./assets/fonts/ProximaNova-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('./assets/fonts/ProximaNova-Regular.eot');
  src: url('./assets/fonts/ProximaNova-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/ProximaNova-Regular.woff') format('woff'),
    url('./assets/fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Bold';
  src: url('./assets/fonts/Gilroy-ExtraBold.otf');
  font-weight: normal;
  font-style: normal;
}

body.body {
  background-color: white;
  margin: 0;
  font-family: 'Proxima Nova Regular', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.redux-toastr .toastr.rrt-error,
.redux-toastr .toastr.rrt-success {
  display: flex;
  width: 350px;
}
.rrt-middle-container {
  align-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  word-break: break-word;
}

.rrt-holder {
  width: 70px;
  height: 70px;
  position: relative;
  display: flex;
  align-items: center;
}

.redux-toastr .toastr .rrt-left-container {
  display: flex;
  align-items: center;
  align-content: center;
}

.redux-toastr .toastr .rrt-left-container .toastr-icon {
  margin: 12px 0 5px 25px;
}

.MuiTableContainer-root::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}
.MuiTableContainer-root::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background: #888;
}
.MuiTableContainer-root::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.before-after-insert {
  background: #e8eefe;
  margin-right: 10px;
  height: 25px;
}

.hovered {
  background: #dae4fc !important;
}

html,
body,
#root {
  height: 100%;
}

#root {
  overflow: auto;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #f9f9fb;
}
::-webkit-scrollbar-thumb {
  background: #c0c0c0;
}
::-webkit-scrollbar-thumb:hover {
  background: #c0c0c0;
}
