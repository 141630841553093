.formContent {
  min-height: 230px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.formActions {
  display: flex;
  justify-content: flex-end;
}

.divider {
  margin-bottom: 20px;
}

.editNoteIcon {
  margin-top: 5px;
  margin-left: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.modal {
  max-width: 1000px;
}

.modalContent {
  padding-top: 0;

  :global {
    .MuiListSubheader-root {
      line-height: 30px;
    }

    .MuiListItem-root {
      padding-top: 3px;
      padding-bottom: 3px;
      .MuiCheckbox-root {
        padding: 0;
      }
      .MuiListItemText-root {
        margin-top: 1px;
        margin-bottom: 1px;
      }
    }
  }

  .cancelButton {
    margin-right: 20px;
  }
}
