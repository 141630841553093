.MuiDialog-root.condition-dialog-modal {
  .MuiPaper-root {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    max-width: 645px;
    width: 100%;
  }
  .MuiDialogTitle-root {
    width: 100%;
  }
  .form {
    width: 100%;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiFormControlLabel-root {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: column-reverse;
    margin: 0;
    padding: 0;
    width: 100%;
    .MuiTypography-root {
      color: #1B2B4B;
      height: 17px;
      font-size: 14px;
      align-self: flex-start;
      font-family: 'Proxima Nova Light', sans-serif;
      line-height: 17px;
      margin-bottom: 15px;
    }
  }
  .MuiInputBase-input {
    padding-left: 25px;
    padding-right: 25px;
  }
  .MuiInputBase-root {
    display: flex;
    align-content: center;
    width: 100%;
    height: 53px;
    background: #F9F9FB;
    border-radius: 5px;
    font-size: 14px;
    text-indent: 15px;
    cursor: pointer;
    &:active,
    &:focus,
    &:hover {
      background-color: #f4f6f9;
    }
    &:before,
    &:after {
      content: none;
    }
  }
  .body {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    border-top: 1px solid #E2E4E9;
    border-bottom: 1px solid #E2E4E9;
    padding: 25px;
    width: 100%;
  }
  .form-field {
    width: 100%;
    margin-bottom: 15px;
    min-height: 76px;
    .MuiSelect-root {
      padding-left: 10px;
      &:focus {
        background-color: transparent;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 25px;
    button {
      margin-left: 14px;
    }
  }
}
